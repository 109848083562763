/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    signal: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.08.234l.179.727a7.3 7.3 0 00-2.01.832l-.383-.643A7.9 7.9 0 016.079.234zm3.84 0L9.742.96a7.3 7.3 0 012.01.832l.388-.643A8 8 0 009.92.234m-8.77 3.63a8 8 0 00-.916 2.215l.727.18a7.3 7.3 0 01.832-2.01l-.643-.386zM.75 8a7 7 0 01.081-1.086L.091 6.8a8 8 0 000 2.398l.74-.112A7 7 0 01.75 8m11.384 6.848l-.384-.64a7.2 7.2 0 01-2.007.831l.18.728a8 8 0 002.211-.919M15.251 8q0 .547-.082 1.086l.74.112a8 8 0 000-2.398l-.74.114q.082.54.082 1.086m.516 1.918l-.728-.18a7.3 7.3 0 01-.832 2.012l.643.387a8 8 0 00.917-2.219m-6.68 5.25c-.72.11-1.453.11-2.173 0l-.112.742a8 8 0 002.396 0l-.112-.741zm4.75-2.868a7.2 7.2 0 01-1.537 1.534l.446.605a8 8 0 001.695-1.689zM12.3 2.163c.587.432 1.105.95 1.537 1.537l.604-.45a8 8 0 00-1.69-1.691zM2.163 3.7A7.2 7.2 0 013.7 2.163l-.45-.604a8 8 0 00-1.691 1.69l.604.45zm12.688.163l-.644.387c.377.623.658 1.3.832 2.007l.728-.18a8 8 0 00-.916-2.214M6.913.831a7.3 7.3 0 012.172 0l.112-.74a8 8 0 00-2.396 0zM2.547 14.64L1 15l.36-1.549-.729-.17-.361 1.548a.75.75 0 00.9.902l1.548-.357zM.786 12.612l.732.168.25-1.073A7.2 7.2 0 01.96 9.74l-.727.18a8 8 0 00.736 1.902l-.184.79zm3.5 1.623l-1.073.25.17.731.79-.184c.6.327 1.239.574 1.902.737l.18-.728a7.2 7.2 0 01-1.962-.811zM8 1.5a6.5 6.5 0 00-6.498 6.502 6.5 6.5 0 00.998 3.455l-.625 2.668L4.54 13.5a6.502 6.502 0 006.93-11A6.5 6.5 0 008 1.5"/>',
    },
});
